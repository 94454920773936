<template>
  <footer class="footer">
    <div class="footer-content">
      <div class="footer-info">
        <p>&copy; {{ currentYear }} it315.top All rights reserved.</p>
        <p><a href="https://beian.miit.gov.cn" target="_blank">京ICP备19000149号-1</a></p>
      </div>
    </div>
  </footer>
</template>

<script setup>

// Ref for the current year
const currentYear = new Date().getFullYear()

</script>

<style scoped>
.footer {
  color: #515a6e;
  /*padding: 1rem;*/
  text-align: center;
  font-size: 14px;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-links {
  margin-bottom: 1rem;
}

.footer-links a {
  color: #fff;
  text-decoration: none;
  margin: 0 0.5rem;
}

.footer-links a:hover {
  text-decoration: underline;
}

.footer-info p {
  margin: 0.5rem 0;
}
</style>
